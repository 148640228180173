import React, { FC } from "react"

const MenuIcon: FC<{ open: boolean }> = ({ open }) => {
  return open ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <line x1="3.5" x2="16.5" y1="3.5" y2="16.5" stroke="#000" />
        <line x1="3.5" x2="16.5" y1="16.5" y2="3.5" stroke="#000" />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <line x1="3.438" x2="16.563" y1="4.5" y2="4.5" stroke="#000" />
        <line x1="3.438" x2="16.563" y1="10" y2="10" stroke="#000" />
        <line x1="3.438" x2="16.563" y1="16" y2="16" stroke="#000" />
      </g>
    </svg>
  )
}

export default MenuIcon
