import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll/modules"
import React, { FC } from "react"
import { UnstyledLink } from "./unstyled-link"

/*
 * since gatsby-plugin-intl creates separate routes for each languages we can't
 * use hash routes (e.g. <Link to={"/#references"}>) as this will break the routing.
 * As a workaround, we make the links in the nav-bar simply link back to the index
 * page whenever we aren't currently on it
 */
export const CustomScrollLink: FC<{
  to: string
  title: string
  goToIndex?: boolean
  onClick?: () => void
}> = ({ to, title, goToIndex, onClick, children }) => {
  return goToIndex ? (
    <UnstyledLink to={"/"}>{children}</UnstyledLink>
  ) : (
    <StyledScrollLink
      to={to}
      duration={2000}
      smooth
      offset={-100}
      title={title}
      onClick={onClick}
    >
      {children}
    </StyledScrollLink>
  )
}

const StyledScrollLink = styled(ScrollLink)`
  cursor: pointer;
`
