import styled, { css } from "styled-components"
import React, { useState } from "react"
import Sticky from "react-sticky-el"
import { changeLocale, useIntl } from "gatsby-plugin-intl"
import { LogoText } from "./logo"
import { Link } from "gatsby"
import media from "styled-media-query"
import MenuIcon from "./icons/menu-icon"
import {
  trackEvent,
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventLabel,
} from "../analytics"
import { LimitedWidth } from "./layout/limited-width"
import { CustomScrollLink } from "./custom-scroll-link"

const Header = () => {
  const { formatMessage, locale } = useIntl()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const contactLabel = formatMessage({ id: "navbar.contact" })

  const renderNavLinks = () => (
    <>
      <li>
        <CustomScrollLink
          to={"contact"}
          title={contactLabel}
          onClick={() =>
            trackEvent(
              TrackingEventAction.CLICK,
              TrackingEventCategory.NAVIGATION,
              TrackingEventLabel.GO_TO_SECTION,
              "contact"
            )
          }
        >
          {contactLabel}
        </CustomScrollLink>
      </li>
    </>
  )

  const renderLocaleItems = () => (
    <>
      <li>
        <LocaleSwitcherButton
          active={locale === "de"}
          onClick={() => {
            changeLocale("de")
          }}
        >
          de
        </LocaleSwitcherButton>
      </li>
      <li>
        <LocaleSwitcherButton
          active={locale === "en"}
          onClick={() => {
            changeLocale("en")
          }}
        >
          en
        </LocaleSwitcherButton>
      </li>
    </>
  )

  return (
    <StickyBar stickyClassName={"sticky"}>
      <StyledHeader>
        <LimitedWidth>
          <Nav>
            <Link to={"/"}>
              <LogoText width={90} />
            </Link>
            <NavList>{renderNavLinks()}</NavList>
            <LocaleList>{renderLocaleItems()}</LocaleList>
          </Nav>
          <MobilNav>
            <LogoAndIconContainer>
              <Link to={"/"}>
                <LogoText width={90} />
              </Link>
              <MenuIconContainer
                onClick={() => {
                  setIsMobileNavOpen((v) => !v)
                }}
              >
                <MenuIcon open={isMobileNavOpen} />
              </MenuIconContainer>
            </LogoAndIconContainer>
            <MobileMenuContainer open={isMobileNavOpen}>
              <MobileNavList>{renderNavLinks()}</MobileNavList>
              <MobileLocaleList>{renderLocaleItems()}</MobileLocaleList>
            </MobileMenuContainer>
          </MobilNav>
        </LimitedWidth>
      </StyledHeader>
    </StickyBar>
  )
}

export default Header

const Nav = styled.nav`
  display: flex;
  align-items: center;
  ${media.lessThan("large")`
    display: none;
  `}
`

const MobilNav = styled.nav`
  ${media.greaterThan("large")`
    display: none;
  `}
`

const StickyBar = styled(Sticky)`
  transition: box-shadow 0.3s;
  line-height: 1;
  z-index: 100;

  & .sticky {
    z-index: 1;
    box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.4);
  }
`

const StyledHeader = styled.header`
  background: white;
  padding: 1.5rem 0;

  ${media.lessThan("large")`
    padding: 1rem 0;
  `}
`

const commonListStyles = css`
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.07em;
  list-style: none;
  padding: 0;
  margin: 0;

  ${media.lessThan("large")`
    font-size: 1rem;
  `}
`

const NavList = styled.ul`
  ${commonListStyles};
  display: grid;
  grid-auto-flow: column;
  margin-left: auto;
  grid-column-gap: 2rem;

  & > li {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      display: block;
      height: 1px;
      width: 0;
      background: #222;
      bottom: -5px;
      transition: width 0.3s;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
`

const MobileNavList = styled.ul`
  ${commonListStyles};
  display: grid;
  grid-row-gap: 1rem;
  text-align: center;
`

const LocaleList = styled.ul`
  ${commonListStyles};
  margin-left: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.25rem;
`

const MobileLocaleList = styled.ul`
  ${commonListStyles};
  justify-content: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  margin-top: 1rem;
`

const LocaleSwitcherButton = styled.button`
  cursor: pointer;
  background: none;
  text-transform: uppercase;
  border: none;
  opacity: 0.5;
  padding: 0;
  ${({ active }: { active: boolean }) =>
    active &&
    css`
      opacity: 1;
    `}
`

const LogoAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`

const MobileMenuContainer = styled.div`
  margin-top: 1rem;
  display: ${({ open }: { open: boolean }) => (open ? "block" : "none")};
`

const MenuIconContainer = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
`
