import styled, { css } from "styled-components"
import { Link } from "gatsby"

// strips any styling from <a> tags rendered by the <Link> components
// useful for non-text links

export const resetLinkStyles = css`
  &:visited,
  &:active,
  &:focus,
  &:hover {
    color: inherit;
  }

  text-decoration: none;
`

// the <Link /> component should only be used for internal links
export const UnstyledLink = styled(Link)`
  ${resetLinkStyles}
`

export const UnstyledExternalLink = styled.a`
  ${resetLinkStyles}
`
