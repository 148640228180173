export enum TrackingEventAction {
  CLICK = "click",
  INPUT_FIELD_ENTER = "inputFieldEnter",
  WAITED_FOR = "waitedFor",
}

export enum TrackingEventCategory {
  NAVIGATION = "navigation",
  CONTACT_FORM = "contactForm",
  VIDEO_INTERACTION = "videoInteraction",
}

export enum TrackingEventLabel {
  GO_TO_SECTION = "goToSection",
  SUBMIT_CONTACT_FORM = "submitContactForm",
  ENTER_CONTACT_FORM_FIELD = "enterContactFormField",
  PLAY_VIDEO = "playVideo",
  FINISH_VIDEO = "finishVideo",
}

export const trackEvent = (
  action: TrackingEventAction,
  category: TrackingEventCategory,
  label?: TrackingEventLabel,
  value?: string
) => {
  const trackingPayload = [
    "trackEvent",
    action.toString(),
    category.toString(),
    label ? label.toString() + (value ? "::" + value : "") : undefined,
  ]
  // @ts-ignore: globally imported for matomo
  if (window._paq) {
    // @ts-ignore
    _paq.push(trackingPayload)
  } else {
    console.log(trackingPayload)
  }
}
