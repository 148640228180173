import styled from "styled-components"
import media from "styled-media-query"
import { clamp } from "../../styles/helpers"

export const OneByOneGrid = styled.div<{ $alignTop?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${clamp(1.5, 3)};
  align-items: ${({ $alignTop }) => ($alignTop ? "top" : "center")};

  ${media.lessThan("large")`
    grid-template-columns: 1fr;
  `}
`

export const TwoByOneGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${clamp(1.5, 3)};
  align-items: center;

  ${media.lessThan("large")`
    grid-template-columns: 1fr;
  `}
`
