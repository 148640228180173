import React, { FC } from "react"
import LogoSvg from "../assets/images/logo.svg"
import LogoTextSvg from "../assets/images/logo-text.svg"
import LogoReplySvg from "../assets/images/logo-reply.svg"

type Props = {
  width?: number
}

export const Logo: FC<Props> = ({ width }) => <LogoSvg style={{ width }} />

export const LogoText: FC<Props> = ({ width }) => (
  <LogoTextSvg style={{ width }} />
)

export const LogoReply: FC<Props> = ({ width }) => (
  <LogoReplySvg style={{ width }} />
)
