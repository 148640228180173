import React, { FC } from "react"
import styled from "styled-components"
import Footer from "./footer"
import Header from "./header"

const Page: FC = ({ children }) => (
  <Wrapper>
    <Header />
    {children}

    <Footer />
  </Wrapper>
)

export default Page

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
