import React from "react"
import styled from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { LimitedWidth } from "./layout/limited-width"
import { clamp } from "../styles/helpers"
import { OneByOneGrid } from "./layout/grid"
import { Link } from "gatsby"
import { LogoReply } from "./logo"

const Footer = () => {
  const { locale } = useIntl()

  return (
    <FooterContainer>
      <LimitedWidth>
        <OneByOneGrid>
          <div>
            <LogoContainer>
              <LogoReply />
            </LogoContainer>
            &copy; {new Date().getFullYear()} Delta by Comsysto Reply
          </div>

          <Legal>
            <a
              href={`https://legal.comsysto.com/comsystoreply.de/${locale}/impressum/`}
            >
              <FormattedMessage id="footer.imprint" />
            </a>

            <br />

            <a
              href={`https://legal.comsysto.com/comsystoreply.de/${locale}/datenschutz/`}
            >
              <FormattedMessage id="footer.privacy-policy" />
            </a>

            <br />

            <Link to="/tracking-privacy-policy">
              <FormattedMessage id="footer.tracking-privacy-policy" />
            </Link>
          </Legal>
          {process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" && (
            <div>Staging Environment</div>
          )}
        </OneByOneGrid>
      </LimitedWidth>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer`
  padding: ${clamp(2, 4)} 0;
  background: ${({ theme }) => theme.semanticColor.primary};

  color: white;
`

const LogoContainer = styled.div`
  max-width: 400px;
  margin-bottom: 1rem;
`

const Legal = styled.div`
  text-align: right;
  line-height: 1.4;

  a:hover {
    text-decoration: underline;
  }
`
